var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.nombreModal,"title":_vm.title,"centered":"","size":"xl","body-class":"p-0","hide-footer":true},on:{"shown":_vm.onOpenModal,"hidden":function($event){$event.preventDefault();return _vm.onCloseModal($event)}}},[_c('form-wizard',{attrs:{"color":"#26a5ea","start-index":_vm.configs.currentStep,"title":null,"subtitle":null,"shape":"square","step-size":"xs","back-button-icon":"EditIcon","next-button-text":"Siguiente","back-button-text":"Anterior","finish-button-text":"Guardar Matrícula"},scopedSlots:_vm._u([{key:"footer",fn:function(ref){
var activeTabIndex = ref.activeTabIndex;
var nextTab = ref.nextTab;
var prevTab = ref.prevTab;
var nextDisabled = ref.nextDisabled;
var prevDisabled = ref.prevDisabled;
var isLastStep = ref.isLastStep;
return [_c('div',{staticClass:"d-flex justify-content-between pt-50 mb-25"},[_c('b-form-checkbox',{staticClass:"mr-auto ml-0 mt-75",attrs:{"id":"chkCrearOtro"},model:{value:(_vm.configs.chkCrearOtro),callback:function ($$v) {_vm.$set(_vm.configs, "chkCrearOtro", $$v)},expression:"configs.chkCrearOtro"}},[_c('small',[_vm._v("Crear otra matrícula")])]),(activeTabIndex > 0)?_c('b-button',{staticClass:"ml-50",attrs:{"variant":"outline-secondary","disabled":prevDisabled},on:{"click":prevTab}},[_vm._v(" Anterior ")]):_vm._e(),(!isLastStep)?_c('b-button',{staticClass:"ml-50 mr-0",attrs:{"variant":"primary","disabled":nextDisabled},on:{"click":nextTab}},[_vm._v(" Siguiente ")]):_vm._e(),(isLastStep)?_c('b-button',{staticClass:"ml-50 mr-0",attrs:{"variant":"primary"},on:{"click":_vm.validaApoderado}},[_vm._v(" Finalizar ")]):_vm._e()],1)]}}])},[_c('tab-content',{attrs:{"title":"Estudiante","before-change":_vm.validaEstudiante}},[_c('TabEstudiante',{ref:"tabEstudiante",attrs:{"crud":"c","matricula":_vm.dataForm},on:{"update:matricula":function($event){_vm.dataForm=$event}}})],1),_c('tab-content',{attrs:{"title":"Apoderado"}},[_c('TabApoderado',{ref:"tabApoderado",attrs:{"crud":"c","matricula":_vm.dataForm},on:{"update:matricula":function($event){_vm.dataForm=$event}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }