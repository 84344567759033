<template>
  <b-modal
    :id="nombreModal"
    :title="title"
    centered
    size="xl"
    body-class="p-0"
    :hide-footer="true"
    @shown="onOpenModal"
    @hidden.prevent="onCloseModal"
  >
    <form-wizard
      color="#26a5ea"
      :start-index="configs.currentStep"
      :title="null"
      :subtitle="null"
      shape="square"
      step-size="xs"
      back-button-icon="EditIcon"
      next-button-text="Siguiente"
      back-button-text="Anterior"
      finish-button-text="Guardar Matrícula"
    > 
      <tab-content title="Estudiante"
        :before-change="validaEstudiante"
      >
        <TabEstudiante
          crud="c"
          ref="tabEstudiante"
          :matricula.sync="dataForm"
        />
      </tab-content>

      <tab-content title="Apoderado">
        <TabApoderado 
          crud="c" 
          ref="tabApoderado"
          :matricula.sync="dataForm" 
        />
      </tab-content>

      <!-- Footer -->
      <template #footer="{ activeTabIndex, nextTab, prevTab, nextDisabled, prevDisabled, isLastStep }">
        <div class="d-flex justify-content-between pt-50 mb-25">
          <b-form-checkbox
            id="chkCrearOtro"
            v-model="configs.chkCrearOtro"
            class="mr-auto ml-0 mt-75"
          >
            <small>Crear otra matrícula</small>
          </b-form-checkbox>
          <b-button
            v-if="activeTabIndex > 0" 
            variant="outline-secondary"
            class="ml-50"
            :disabled="prevDisabled"
            @click="prevTab"
          >
            Anterior
          </b-button>
          <b-button
            v-if="!isLastStep"
            variant="primary"
            class="ml-50 mr-0"
            :disabled="nextDisabled"
            @click="nextTab"
          >
            Siguiente
          </b-button>

          <b-button
            v-if="isLastStep"
            variant="primary"
            class="ml-50 mr-0"
            @click="validaApoderado"
          >
            Finalizar
          </b-button>
        </div>
      </template>

    </form-wizard>
  </b-modal>
</template>

<script>
import { BModal, BFormCheckbox, BButton, VBModal } from 'bootstrap-vue';
import { rut } from '@core/mixins/ui/rut';
import { formatos } from '@core/mixins/ui/formatos';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import TabEstudiante from './MatriculasFormTabs/TabEstudiante.vue';
import TabApoderado from './MatriculasFormTabs/TabApoderado.vue';

export default {
  components: {
    BModal,
    VBModal,
    BFormCheckbox,
    BButton,
    FormWizard,
    TabContent,
    TabEstudiante,
    TabApoderado,
  },
  data() {
    return {
      configs: {
        currentStep: 0,
        chkCrearOtro: false,
      },
      dataForm: {
        estudiante: {
          rut: '123',
          nombre: 'asd',
          segundo_nombre: '',
          primer_apellido: 'asd',
          segundo_apellido: 'asd',
          genero: 2,
          fecha_nacimiento: '2024-07-11',
          correo: 'a@lc.cl',
          celular: '569',
          id_comuna: 56,
          nombre_calle: 'asd',
          numero: 123,
          tipo_identificacion: 1,
          fecha_ingreso: this.formatoFechaHoy(),
          id_pais: 45,
          id_cursos: null,
          procedencia: 27,
          repitencia: false,
          prioritario: false,
          beneficio: false,
          pie: false,
          religion: false,
          junaeb: false,
          autorizacion: false,
          aprendizaje: false,
          transporte: false,
        },
        apoderado: {
          rut: '',
          nombre: '',
          segundo_nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          correo: '',
          celular: '569',
          genero: null,
          fecha_nacimiento: '',
          nombre_calle: '',
          numero: '',
        },
        tipoIdentificacionEstudiante: 1,
        nucleo: {
          vive: null,
          renta: null,
          integrantes: null,
          camas: null,
          hacinamiento: null,
          certificado_nacimiento: false,
          certificado_estudio: false,
          certificado_personalidad: false,
          nivel_educacional_padre: null,
          nivel_educacional_madre: null,
          id_persona_rol_padre: null,
          id_persona_rol_madre: null,
        },
        clinico: {
          prevision: 'Fonasa',
          actividad_fisica: false,
          documento_actividad_fisica: false,
          patologias: '',
          tratamiendo_medico: '',
          horario_medicacion: '',
          id_persona_rol_emergencia: null,
        },
      },
    };
  },
  mixins: [rut, formatos],
  props: {
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      console.log('Form submitted');
      console.log('this.dataForm :', this.dataForm)
    },
    onOpenModal() {
      this.resetWizard();
    },
    onCloseModal() {
      this.configs.currentStep = 1;
      this.$bvModal.hide(this.nombreModal);
      this.resetWizard();
    },
    validaEstudiante() {
      return new Promise((resolve, reject) => {
        if (this.$refs.tabEstudiante.validarFormulario()) {
          resolve(true);
        } else {
          reject()
        }
      });
    },
    validaApoderado() {
      return new Promise((reject) => {
        if (this.$refs.tabApoderado.validarFormulario()) {
          this.onSubmit()
        } else {
          reject()
        }
      });
    },
    resetWizard() {
      this.configs.currentStep = 0;
      this.configs.chkCrearOtro = false;
    },
  },
};
</script>

<style lang="scss">
.b-modal {
  width: 100%;
  max-width: 100%;
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}
@import '@core/scss/vue/libs/vue-wizard.scss';
</style>
